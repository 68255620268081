import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText, Button
} from 'reactstrap'


const NavbarComponent = ({ }) => {

  const history = useHistory();

  return (
    <div>
      <Navbar
        color="light"
        expand="md"
        light
      >
       <div className="container horizontal-align {
        display: inline-flex;
      }">
       <NavbarBrand className="cursor-pointer" onClick={()=>{history.push('/home')}}>
       Admin Dashboard
     </NavbarBrand>
     <NavbarToggler onClick={function noRefCheck() { }} />
     <Collapse navbar>
       <Nav
         className="me-auto"
         navbar
       >
        {/* <NavItem>
           <NavLink href="/components/">
             Inquiries
           </NavLink>
         </NavItem>
         
         <UncontrolledDropdown
           inNavbar
           nav
         >
           <DropdownToggle
             caret
             nav
           >
             Options
           </DropdownToggle>
           <DropdownMenu right>
             <DropdownItem>
               Option 1
             </DropdownItem>
             <DropdownItem>
               Option 2
             </DropdownItem>
             <DropdownItem divider />
             <DropdownItem>
               Reset
             </DropdownItem>
           </DropdownMenu>
         </UncontrolledDropdown>*/}
       </Nav>

       <Button className="btn-primary-startNow p-2 me-2" onClick={()=>{history.push('/dealers')}}>
       Dealers
     </Button>
       <Button className="btn-primary-startNow p-2 me-2" onClick={()=>{history.push('/announce')}}>
       Announce
     </Button>
       <Button className="btn-primary-startNow p-2" onClick={()=>{history.push('/inquiryList')}}>
         Inquiries
       </Button>
     </Collapse></div>
      </Navbar>
    </div>
  )


};
export default NavbarComponent;