import { useState, useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button } from 'reactstrap'
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import { Table } from 'react-bootstrap';
const SuperSubCatList = ({ }) => {
  const history = useHistory();
  const {catId, subCatId } = useParams();
  const [superSupCategoryList, setSuperSubCategoryList] = useState([])
  useEffect(() => {
    getSuperSubCategoryList()
  }, [])

  const getSuperSubCategoryList = () => {
    axios.get(`${base_url}/getAllSuperSubCat`).then((response) => {
      console.log('getAllCourseResponse: ', response.data)
      setSuperSubCategoryList(response.data.data)
    });
  }

  const onDelete = (courseId) => {

    axios.get(base_url + '/deleteSuperSubCategory/' + courseId).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message)
        getSuperSubCategoryList()
      }
    });
  }
  return (
    <div>
      <Button className='btn-primary-startNow btn-font mt-3 p-2 mb-1' onClick={() => { history.push('/addSuperSubCat/null') }}>Add New Make/Vehicle</Button>
      <div class="profile-banner mt-3">
        <h1>Make/Vehicle List</h1>
      </div>
      <div >

      <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Make/Vehicle Name</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {superSupCategoryList?.map((item, index) => (
          <tr>
            <td>{index + 1}</td>
            <td>{item.supSubCatName}</td>
            <td></td>
            <td> {/*<Button className='ms-3' onClick={()=>{
              history.push('/productList/'+catId+'/'+subCatId+'/'+item._id)
            }} >View Products</Button>*/}
            <Button className='ms-3' onClick={() => { history.push('/addSuperSubCat/'+item._id+'?isEdit=1') }} >Edit</Button>
            <Button className='ms-3' onClick={() => {
              if (window.confirm('Are you sure you want to delete?')) {
                onDelete(item._id)
              }
            }}>Delete</Button></td>
          </tr>
        ))}
      </tbody>
    </Table>
      </div>
     
          
    </div>
  );


};
export default SuperSubCatList;