import { useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import Page from '../Page';
const AddSubCat = () => {

    const [reqObj, setReqObj] = useState({});
    const { id } = useParams();
    const history = useHistory()
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const isEdit = params.get('isEdit');

    const [categoryList, setCategoryList] = useState([])
    useEffect(() => {
        getCategoryList()

    }, [])

    const getCategoryList = () => {
        axios.get(base_url + '/getAllCategories').then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setCategoryList(response.data.data)

        });
    }

    useEffect(() => {
        if (categoryList?.length > 1 && isEdit === "1") {
            getSubCatDetail()
        }
    }, [categoryList])


    const getSubCatDetail = () => {
        axios.get(base_url + '/getSubCategoryDetail/' + id).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setReqObj({ ...reqObj, catId: response?.data?.data?.catId });
            setReqObj({ ...reqObj, subCatName: response?.data?.data?.subCatName });

            console.log('catId: ', reqObj?.subCatName)
        });
    }


    const onSubmit = () => {
        if (isEdit !== "1") {
            if (reqObj.catId === null || reqObj.catId === undefined) {
                alert("Please select category.")
                return;
            }
        }

        if (reqObj.subCatName === '' || reqObj.subCatName === undefined) {
            alert("Please enter product.")
            return;
        }

        if (isEdit === "1") {

            let req = {
                subCatId: id,
                subCatName: reqObj.subCatName
            }

            const formData = new FormData();
            formData.append('subCatId', id);
            formData.append('subCatName', reqObj.subCatName);
            if (reqObj.subCatImg !== undefined && reqObj.subCatImg !== null)
                formData.append('subCatImg', reqObj.subCatImg);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }


            axios.post(base_url + '/editSubCategory', formData, config).then((response) => {
                if (response.status === 200)
                    toast.success("Category updated successfully.")
                history.goBack();
            });
        } else {

            const formData = new FormData();
            formData.append('catId', reqObj.catId);
            formData.append('subCatName', reqObj.subCatName);
            formData.append('subCatImg', reqObj.subCatImg);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            axios.post(base_url + '/createSubCategory', formData, config).then((response) => {
                if (response.status === 200)
                    toast.success("Category created successfully.")
                history.goBack();
            });
        }



    }
    return (<Page><div className='margin-bothside'>
        <div class="profile-banner mt-3">
            <h1>{isEdit === "1" ? "Update Vehicle Category" : "Add Vehicle Category"}</h1>
        </div>
        <Form>

            {isEdit !== "1" ? <FormGroup>
                <Label for="exampleEmail">
                    Select Product Category
                </Label>
                <Input
                    className="mb-3"
                    type="select"
                    onChange={(e) => { setReqObj({ ...reqObj, catId: e.target.value }); }}
                    value={reqObj.catId}
                >
                    <option value={null}>
                        Select Product Category
                    </option>
                    {categoryList.map((item, index) => {
                        return <option value={item._id}>
                            {item.catName}
                        </option>
                    })}

                </Input>

            </FormGroup> : ""}

            <FormGroup>
                <Label for="exampleEmail">
                    Vehicle Category Name
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter your Vehicle Category name"
                    type="text"
                    value={reqObj.subCatName}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, subCatName: e.target.value });
                        }}
                />
            </FormGroup>

            <FormGroup>
                <Input type="file" name="file" id="exampleFile"
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, subCatImg: e.target.files[0] });
                        }
                    } />

            </FormGroup>


            <Button onClick={onSubmit}>
                {isEdit === "1" ? "Update" : "Submit"}
            </Button>
        </Form>
    </div></Page>)

};
export default AddSubCat;