import { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button } from 'reactstrap'
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import moment from 'moment'
import { Table } from 'react-bootstrap';
import { tempObj } from '../../utils/tempData'
import Page from '../Page';
const Announcement = ({ }) => {
  const history = useHistory();
  const [inquiryList, setInquiryList] = useState([])
  useEffect(() => {
    getInquiryList()
  }, [])

  const getInquiryList = () => {
    axios.get(base_url + '/getAnnounceList').then((response) => {
      console.log('getAllCourseResponse: ', response.data)
      setInquiryList(response.data.data)
    });
  }

  const onDelete = (courseId) => {

    axios.get(base_url + '/deleteAnnounce/' + courseId).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message)
        getInquiryList()
      }
    });
  }
  return (
    <Page><div className='container'>

      <div class="profile-banner mt-3">
        <h1>Announcements</h1>

        <Button className='btn-primary-startNow me-2 add_form' onClick={() => { history.push('/add-announce/null') }}>Add Announcement</Button>
      </div>

      <div >
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {inquiryList?.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item.title}</td>
                <td>{item.desc}</td>
                <td> {/*<Button className='ms-3' onClick={()=>{
              history.push('/productDetailList/'+catId+'/'+subCatId+'/'+supSubCatId+"/"+item._id)
            }} >View Product Details</Button>*/}
                  <Button className='ms-3' onClick={() => {
                    tempObj = item
                    history.push('/add-announce/' + item._id + '?isEdit=1')
                  }} >Edit</Button>
                  <Button className='ms-3' onClick={() => {
                    if (window.confirm('Are you sure you want to delete?')) {
                      onDelete(item._id)
                    }
                  }}>Delete</Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>


    </div>
    </Page>
  );


};
export default Announcement;