import { useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import Page from '../Page';
const AddDealer = () => {

    const [reqObj, setReqObj] = useState({});
    const { id } = useParams();
    const history = useHistory()
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const isEdit = params.get('isEdit');

    const [categoryList, setCategoryList] = useState([])
    const [subCategoryList, setSubCategoryList] = useState([])
    const [superSupCategoryList, setSuperSubCategoryList] = useState([])
    const [productList, setProductList] = useState([])

    useEffect(() => {
        if (isEdit === "1") {
            getUserDetails()
        }
    }, [])

    const getUserDetails = () => {
        axios.get(`${base_url}/getUserDetail/` + id).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            // setReqObj({ ...reqObj, applicationName: response?.data?.data?.applicationName });
            // setReqObj({ ...reqObj, jkp_part_no: response?.data?.data?.jkp_part_no });
            // setReqObj({ ...reqObj, reference_part_no: response?.data?.data?.reference_part_no });
            // setReqObj({ ...reqObj, dimention: response?.data?.data?.dimention });
            // setReqObj({ ...reqObj, sealType: response?.data?.data?.sealType });
            // setReqObj({ ...reqObj, mrp: response?.data?.data?.mrp });

            var req = {...response?.data?.data,...{userId:id}}

            setReqObj(req)

        });
    }

    const onSubmit = () => {
        // let req = {
        //     catId:catId,
        //     subCatId:subCatId,
        //     supSubCatId:supSubCatId,
        //     parentId:parentId,
        //     applicationName:reqObj.applicationName,
        //     jkp_part_no:reqObj.jkp_part_no,
        //     reference_part_no:reqObj.reference_part_no,
        //     dimention:reqObj.dimention,
        //     sealType:reqObj.sealType,
        //     mrp:reqObj.mrp

        // }

        if (isEdit !== "1") {
            if (reqObj.name === null || reqObj.name === undefined) {
                alert("Please please enter name.")
                return;
            }

            if (isEdit !== "1") {
                if (reqObj.email === null || reqObj.email === undefined) {
                    alert("Please enter email.")
                    return;
                }

                if (reqObj.mobile_number === null || reqObj.mobile_number === undefined) {
                    alert("Please enter contact.")
                    return;
                }
            }

            if (reqObj.gst_number === null || reqObj.gst_number === undefined) {
                alert("Please enter GST Number.")
                return;
            }

            if (reqObj.city === null || reqObj.city === undefined) {
                alert("Please enter city.")
                return;
            }

        }

        if (isEdit === "1") {

            // setReqObj({ ...reqObj, userId: id });
            axios.post(base_url + '/editProfile', reqObj).then((response) => {
                if (response.status === 200)
                    toast.success("Category created successfully.")
                history.goBack();
            });
        } else {
            axios.post(base_url + '/createProfile', reqObj).then((response) => {
                if (response.status === 200)
                    toast.success("Category created successfully.")
                history.goBack();
            });
        }

    }
    return (<Page><div className='margin-bothside'>
        <div class="profile-banner mt-3">
            <h1>{isEdit === "1" ? "Update Product Detail" : "Add Product Detail"}</h1>
        </div>
        <Form>
            <FormGroup>
                <Label for="exampleEmail">
                    Dealer Name:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter name"
                    type="text"
                    value={reqObj.name}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, name: e.target.value });
                        }}
                />
            </FormGroup>

            <FormGroup>
                <Label for="exampleEmail">
                    Email:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter email"
                    type="text"
                    value={reqObj.email}
                    disabled={isEdit === "1" ? true : false}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, email: e.target.value });
                        }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="exampleEmail">
                    Password:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter Password"
                    type="password"
                    value={reqObj.password}
                    disabled={isEdit === "1" ? true : false}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, password: e.target.value });
                        }}
                />
            </FormGroup>

            <FormGroup>
                <Label for="exampleEmail">
                    Country Code:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter country code"
                    type="text"
                    value={"+91"}
                    disabled
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, country_code: e.target.value });
                        }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="exampleEmail">
                    Contact:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter contact"
                    type="text"
                    value={reqObj.mobile_number}
                    disabled={isEdit === "1" ? true : false}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, mobile_number: e.target.value });
                        }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="exampleEmail">
                    GST Number:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter GST Number"
                    type="text"
                    value={reqObj.gst_number}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, gst_number: e.target.value });
                        }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="exampleEmail">
                    City:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter City"
                    type="text"
                    value={reqObj.city}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, city: e.target.value });
                        }}
                />
            </FormGroup>

            <Button onClick={onSubmit}>
                {isEdit === "1" ? "Update" : "Submit"}
            </Button>
        </Form>
    </div></Page>)

};
export default AddDealer;