import { useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import { tempObj } from '../../utils/tempData'
import Page from '../Page';
const AddCourse = () => {

    const [category, setCategory] = useState({});
    const history = useHistory()
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const isEdit = params.get('isEdit');

    useEffect(() => {
        console.log('tempObj: ', tempObj)
        if (isEdit === "1") {
            setCategory({ ...category, catName: tempObj?.catName })
        } else {
            setCategory({ ...category, catName: "" })
        }
    }, [])
    const onSubmit = () => {
        console.log('cat_img: ', isEdit)
        if (isEdit === "0") {
            const formData = new FormData();
            formData.append('catName', category.catName);
            formData.append('cat_img', category.cat_img);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            axios.post(base_url + '/createCategory', formData, config).then((response) => {
                if (response.status === 200)
                    toast.success("Category created successfully.")
                history.goBack();
            });
        } else {

            const formData = new FormData();
            formData.append('catId', tempObj._id);
            formData.append('catName', category.catName);


            if (category.cat_img !== undefined && category.cat_img !== null)
                formData.append('cat_img', category.cat_img);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            axios.post(base_url + '/editCategory', formData, config).then((response) => {
                if (response.status === 200)
                    toast.success("Category updated successfully.")
                history.goBack();
            });
        }

    }

    const uploadCSV = () => {

    }


    return (
        <Page>
            <div className='margin-bothside'>

                <div class="profile-banner mt-3">
                    <h1>{isEdit === "0" ? "Add Product Category" : "Update Product Category"}</h1>
                </div>
                <div>
                    <Button className='btn-primary-startNow  btn-font  p-2 mt-3 mb-1' onClick={() => { uploadCSV() }}>Upload CSV</Button>
                </div>
                <Form>
                    <FormGroup>
                        <Label for="exampleEmail">
                            Product Category Name
                        </Label>
                        <Input
                            id="exampleCName"
                            name="course_name"
                            placeholder="Enter your category name"
                            type="text"
                            value={category.catName}
                            onChange={
                                (e) => {
                                    setCategory({ ...category, catName: e.target.value });
                                }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Input type="file" name="file" id="exampleFile"
                            onChange={
                                (e) => {
                                    setCategory({ ...category, cat_img: e.target.files[0] });
                                }
                            } />

                    </FormGroup>


                    <Button onClick={onSubmit}>
                        {isEdit === "0" ? "Submit" : "Update"}
                    </Button>
                </Form>
            </div>
        </Page>
    )

};
export default AddCourse;