import { useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import Page from '../Page';
import * as XLSX from 'xlsx'
const BulkUpload = () => {

    const [reqObj, setReqObj] = useState({});
    const { id } = useParams();
    const history = useHistory()
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const isEdit = params.get('isEdit');

    const [categoryList, setCategoryList] = useState([])
    const [subCategoryList, setSubCategoryList] = useState([])
    const [superSupCategoryList, setSuperSubCategoryList] = useState([])

    useEffect(() => {
        getCategoryList()
        if (isEdit === "1") {
            getSingleProductDetail()
        }
    }, [])

    const getSingleProductDetail = () => {
        axios.get(`${base_url}/getSingleProductDetail/` + id).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setReqObj({ ...reqObj, prodName: response.data?.data?.prodName });
        });
    }

    const getCategoryList = () => {
        axios.get(base_url + '/getAllCategories').then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setCategoryList(response.data.data)
        });
    }

    const getSubCategoryList = (catId) => {
        axios.get(`${base_url}/getSubCategories/` + catId).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setSubCategoryList(response.data.data)
        });
    }

    const getSuperSubCategoryList = (subCatId) => {
        axios.get(`${base_url}/getSuperSubCategories/` + subCatId).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setSuperSubCategoryList(response.data.data)
        });
    }


    const onSubmit = () => {
        if (isEdit !== "1") {
            if (reqObj.catId === null || reqObj.catId === undefined) {
                alert("Please select devision.")
                return;
            }

            if (reqObj.subCatId === null || reqObj.subCatId === undefined) {
                alert("Please select product.")
                return;
            }

            if (reqObj.supSubCatId === null || reqObj.supSubCatId === undefined) {
                alert("Please select first level category.")
                return;
            }
        }
        if (reqObj.csv_file === null || reqObj.csv_file === undefined) {
            alert("Please select file to upload.")
            return;
        }

        if (isEdit === "1") {
            let req = {
                "prodId": id,
                "prodName": reqObj.prodName
            }
            axios.post(base_url + '/editProduct', req).then((response) => {
                if (response.status === 200)
                    toast.success("Second level Category successfully.")
                history.goBack();
            });
        } else {

            const formData = new FormData();
            formData.append('catName', reqObj.catId);
            formData.append('subCatId', reqObj.subCatId);
            formData.append('supSubCatId', reqObj.supSubCatId);
            formData.append('csv_file', reqObj.csv_file);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            axios.post(base_url + '/createProductDetailWithCSV', formData, config).then((response) => {
                if (response.status === 200)
                    toast.success("Bulk Uploaded successfully successfully.")
                history.goBack();
            });
        }
    }

    const downloadSample = () =>{
        const newThing = [
          {
            "Model Name":"xyz",
            "Application Name":"xyz" ,
            "Bnb Part Number":"xyz",
            "OE Number":"xyz",
            "Dimention":"xyz",
            "Seal Type":"xyz",
            "MRP":123
          }
        ];
    
        const worksheet = XLSX.utils.json_to_sheet(newThing);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
      }

    return (<Page><div className='margin-bothside'>

        <div class="profile-banner mt-3">
            <h1>{isEdit === "1" ? "Update Model" : "Bulk Upload"}</h1>
            <Button className='btn-primary-startNow btn-font mt-3 p-2 mb-1 ms-2' onClick={() => { downloadSample() }}>Download Sample</Button>
        </div>
        <Form>

            {isEdit !== "1" ? <div>
                <FormGroup>
                    <Label for="exampleEmail">
                        Select Product Category
                    </Label>
                    <Input
                        className="mb-3"
                        type="select"
                        onChange={(e) => { getSubCategoryList(e.target.value); setReqObj({ ...reqObj, catId: e.target.value }); }}
                    >
                        <option value={null}>
                            Select Product Category
                        </option>
                        {categoryList.map((item, index) => {
                            return <option value={item._id}>
                                {item.catName}
                            </option>
                        })}

                    </Input>

                </FormGroup>

                <FormGroup>
                    <Label for="exampleEmail">
                        Select Vehicle Category
                    </Label>
                    <Input
                        className="mb-3"
                        type="select"
                        onChange={(e) => { getSuperSubCategoryList(e.target.value); setReqObj({ ...reqObj, subCatId: e.target.value }); }}
                    >
                        <option value={null}>
                            Select Vehicle Category
                        </option>
                        {subCategoryList.map((item, index) => {
                            return <option value={item._id}>
                                {item.subCatName}
                            </option>
                        })}

                    </Input>

                </FormGroup>


                <FormGroup>
                    <Label for="exampleEmail">
                        Select Make/Vehicle
                    </Label>
                    <Input
                        className="mb-3"
                        type="select"
                        onChange={(e) => { setReqObj({ ...reqObj, supSubCatId: e.target.value }); }}
                    >
                        <option value={null}>
                            Select Make/Vehicle
                        </option>
                        {superSupCategoryList.map((item, index) => {
                            return <option value={item._id}>
                                {item.supSubCatName}
                            </option>
                        })}

                    </Input>

                </FormGroup>
            </div> : ""}


            <FormGroup>
            <Label for="exampleEmail">
                        Select Excel File
                    </Label>
                <Input type="file" name="file" id="exampleFile"
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, csv_file: e.target.files[0]});
                        }
                    } />

            </FormGroup>


            <Button onClick={onSubmit}>
                {isEdit === "1" ? "Update" : "Submit"}
            </Button>
        </Form>
    </div>
    </Page>)

};
export default BulkUpload;