import { useState, useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button } from 'reactstrap'
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import { Table } from 'react-bootstrap';
import * as XLSX from "xlsx";
import _ from 'lodash';
const ProductDetailList = ({ }) => {
  const history = useHistory();
  const { catId, subCatId, supSubCatId, parentId } = useParams();
  const [productDetailList, setProductDetailList] = useState([])
  useEffect(() => {
    getProductDetailListApi()
  }, [])

  const getProductDetailListApi = () => {
    axios.get(`${base_url}/getAllProductList`).then((response) => {
      console.log('getAllCourseResponse: ', response.data)
      setProductDetailList(response.data.data)
    });
  }

  const onDelete = (courseId) => {

    axios.get(base_url + '/deleteProductDetail/' + courseId).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message)
        getProductDetailListApi()
      }
    });
  }

  const downloadRecord = (data) =>{
    const newThing = [];
    data.map(item => {
      newThing.push(
        _.mapKeys(item, (value, key) => {
          switch (key) {
            case "_id":
              return "ID";
            case "applicationName":
              return "Application Name";
            case "jkp_part_no":
              return "Bnb Part Number";
            case "reference_part_no":
              return "OE Number";
            case "dimention":
              return "Dimention";
            case "sealType":
              return "Seal Type"
            case "mrp":
              return "MRP";
            default:
              return;
          }
        })
      )
    });

    for (let i = 0; i < newThing.length; i++) {
      // arrayObj[i].stroke = arrayObj[i]['key1'];
      delete newThing[i].undefined;
    }


    const worksheet = XLSX.utils.json_to_sheet(newThing);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  }
  return (
    <div>
      <Button className='btn-primary-startNow btn-font mt-3 p-2 mb-1' onClick={() => { history.push('/addProductDetail/null') }}>Add New Product Detail</Button>
      <Button className='btn-primary-startNow btn-font mt-3 p-2 mb-1 ms-2' onClick={() => { downloadRecord(productDetailList) }}>Download Record</Button>
      <Button className='btn-primary-startNow btn-font mt-3 p-2 mb-1 ms-2' onClick={() => { history.push('/editUpload') }}>Upload Downloaded Record</Button>
      <div class="profile-banner mt-3">
        <h1>Product Detail List</h1>
      </div>
      <div >
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Application name</th>
              <th>Binbow part no</th>
              <th>OE Number</th>
              <th>Dimention</th>
              <th>Seal Type</th>
              <th>MRP</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {productDetailList?.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item.applicationName}</td>
                <td>{item.jkp_part_no}</td>
                <td>{item.reference_part_no}</td>
                <td>{item.dimention}</td>
                <td>{item.sealType}</td>
                <td>{item.mrp}</td>
                <td><Button className='ms-3' onClick={() => { history.push('/addProductDetail/' + item._id + '?isEdit=1') }} >Edit</Button>
                  <Button className='ms-3' onClick={() => {
                    if (window.confirm('Are you sure you want to delete?')) {
                      onDelete(item._id)
                    }
                  }}>Delete</Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>


    </div>
  );


};
export default ProductDetailList;