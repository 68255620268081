import { useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import Page from '../Page';
const AddProduct = () => {

    const [reqObj, setReqObj] = useState({});
    const { id } = useParams();
    const history = useHistory()
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const isEdit = params.get('isEdit');

    const [categoryList, setCategoryList] = useState([])
    const [subCategoryList, setSubCategoryList] = useState([])
    const [superSupCategoryList, setSuperSubCategoryList] = useState([])

    useEffect(() => {
        getCategoryList()
        if (isEdit === "1") {
            getSingleProductDetail()
        }
    }, [])

    const getSingleProductDetail = () => {
        axios.get(`${base_url}/getSingleProductDetail/` + id).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setReqObj({ ...reqObj, prodName: response.data?.data?.prodName });
        });
    }

    const getCategoryList = () => {
        axios.get(base_url + '/getAllCategories').then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setCategoryList(response.data.data)
        });
    }

    const getSubCategoryList = (catId) => {
        axios.get(`${base_url}/getSubCategories/` + catId).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setSubCategoryList(response.data.data)
        });
    }

    const getSuperSubCategoryList = (subCatId) => {
        axios.get(`${base_url}/getSuperSubCategories/` + subCatId).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setSuperSubCategoryList(response.data.data)
        });
    }


    const onSubmit = () => {
        if (isEdit !== "1") {
            if (reqObj.catId === null || reqObj.catId === undefined) {
                alert("Please select devision.")
                return;
            }

            if (reqObj.subCatId === null || reqObj.subCatId === undefined) {
                alert("Please select product.")
                return;
            }

            if (reqObj.supSubCatId === null || reqObj.supSubCatId === undefined) {
                alert("Please select first level category.")
                return;
            }
        }
        if (reqObj.prodName === '' || reqObj.prodName === undefined) {
            alert("Please enter second level category.")
            return;
        }

        if (isEdit === "1") {
            let req = {
                "prodId": id,
                "prodName": reqObj.prodName
            }
            axios.post(base_url + '/editProduct', req).then((response) => {
                if (response.status === 200)
                    toast.success("Second level Category successfully.")
                history.goBack();
            });
        } else {
            axios.post(base_url + '/createProduct', reqObj).then((response) => {
                if (response.status === 200)
                    toast.success("Second level Category created successfully.")
                history.goBack();
            });
        }



    }
    return (<Page><div className='margin-bothside'>

        <div class="profile-banner mt-3">
            <h1>{isEdit === "1" ? "Update Model" : "Add Model"}</h1>
        </div>
        <Form>

            {isEdit !== "1" ? <div>
                <FormGroup>
                    <Label for="exampleEmail">
                        Select Product Category
                    </Label>
                    <Input
                        className="mb-3"
                        type="select"
                        onChange={(e) => { getSubCategoryList(e.target.value); setReqObj({ ...reqObj, catId: e.target.value }); }}
                    >
                        <option value={null}>
                            Select Product Category
                        </option>
                        {categoryList.map((item, index) => {
                            return <option value={item._id}>
                                {item.catName}
                            </option>
                        })}

                    </Input>

                </FormGroup>

                <FormGroup>
                    <Label for="exampleEmail">
                        Select Vehicle Category
                    </Label>
                    <Input
                        className="mb-3"
                        type="select"
                        onChange={(e) => { getSuperSubCategoryList(e.target.value); setReqObj({ ...reqObj, subCatId: e.target.value }); }}
                    >
                        <option value={null}>
                            Select Vehicle Category
                        </option>
                        {subCategoryList.map((item, index) => {
                            return <option value={item._id}>
                                {item.subCatName}
                            </option>
                        })}

                    </Input>

                </FormGroup>


                <FormGroup>
                    <Label for="exampleEmail">
                        Select Make/Vehicle
                    </Label>
                    <Input
                        className="mb-3"
                        type="select"
                        onChange={(e) => { setReqObj({ ...reqObj, supSubCatId: e.target.value }); }}
                    >
                        <option value={null}>
                            Select Make/Vehicle
                        </option>
                        {superSupCategoryList.map((item, index) => {
                            return <option value={item._id}>
                                {item.supSubCatName}
                            </option>
                        })}

                    </Input>

                </FormGroup>
            </div> : ""}


            <FormGroup>
                <Label for="exampleEmail">
                Model Name
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter your Model name"
                    type="text"
                    value={reqObj.prodName}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, prodName: e.target.value });
                        }}
                />
            </FormGroup>


            <Button onClick={onSubmit}>
                {isEdit === "1" ? "Update" : "Submit"}
            </Button>
        </Form>
    </div>
    </Page>)

};
export default AddProduct;