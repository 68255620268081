import { useState, useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button } from 'reactstrap'
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import { Table } from 'react-bootstrap';
import imageUrl from '../../utils/imageBaseUrl';
const SubCatList = ({ }) => {
  const history = useHistory();
  const { catId } = useParams();
  const [subCategoryList, setSubCategoryList] = useState([])


  useEffect(() => {
    getSubCategoryList()
  }, [])

  const getSubCategoryList = () => {
    axios.get(`${base_url}/getAllSubCat`).then((response) => {
      console.log('getAllCourseResponse: ', response.data)
      setSubCategoryList(response.data.data)
    });
  }

  const onDelete = (courseId) => {

    axios.get(base_url + '/deleteSubCategory/' + courseId).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message)
        getSubCategoryList()
      }
    });
  }
  return (
    <div>
      <Button className='btn-primary-startNow btn-font mt-3 p-2 mb-1' onClick={() => { history.push('/addSubCat/' + catId) }}>Add New Vehicle Category</Button>
      <div class="profile-banner mt-3">
        <h1>Vehicle Category List</h1>
      </div>
      <div >

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Vehicle Category Name</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {subCategoryList?.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item.subCatName}</td>
                <td><img src={imageUrl + item.subCatImg} width='60' height='60' /></td>
                <td> {/*<Button className='ms-3' onClick={()=>{
              history.push('/superSubCatList/'+catId+'/'+item._id)
            }} >View super SubCat</Button>*/}
                  <Button className='ms-3' onClick={() => { history.push('/addSubCat/' + item._id + '?isEdit=1') }} >Edit</Button>
                  <Button className='ms-3' onClick={() => {
                    if (window.confirm('Are you sure you want to delete?')) {
                      onDelete(item._id)
                    }
                  }}>Delete</Button></td>
              </tr>
            ))}
          </tbody>
        </Table>

      </div>


    </div>
  );


};
export default SubCatList;