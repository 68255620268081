import NavbarComponent from "./NavbarComponent"

const Page = (props) => {

   
  
    return (
      <div className="page-wrapper-rsp">
        <div className="rspinnerwrapper">
        <NavbarComponent />
          {props.children}
          </div>
        
      </div>
    )
  }
  
  export default Page