import { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button } from 'reactstrap'
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import { tempObj } from '../../utils/tempData'
import { Form, Table } from 'react-bootstrap';
import imageUrl from '../../utils/imageBaseUrl';
import Page from '../Page';

const DealerList = ({ }) => {
  const history = useHistory();
  const [categoryList, setCategoryList] = useState([])
  useEffect(() => {
    getCategoryList()
  }, [])

  const getCategoryList = () => {
    axios.get(base_url + '/getAllUsers').then((response) => {
      console.log('getAllUsersResponse: ', response.data)
      setCategoryList(response.data.data)
    });
  }

  const onDelete = (courseId) => {

    axios.get(base_url + '/deleteUser/' + courseId).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message)
        getCategoryList()
      }
    });
  }
  return (
    <Page>
      <div className='container'>
        <Button className='btn-primary-startNow  btn-font  p-2 mt-3 mb-1' onClick={() => { history.push('/addUser/null') }}>Add New User</Button>

        <div class="profile-banner mt-3">
          <h1>Dealer List</h1>
        </div>

        <div >
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Dealer Name</th>
                <th>Contact</th>
                <th>Email</th>
                <th>City</th>
                <th>GST Number</th>
              </tr>
            </thead>
            <tbody>
              {categoryList?.map((item, index) => (
                <tr>
                  <td className='horizontal-align'>
                    {index + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.mobile_number}  </td>
                  <td>{item?.email}  </td>
                  <td>{item?.city}  </td>
                  <td>{item?.gst_number}  </td>
                  <td>
                    <Button className='ms-3' onClick={() => {
                      tempObj = item;
                      history.push('/addUser/' + item._id + '?isEdit=1')
                    }}  >Edit</Button>
                    <Button className='ms-3' onClick={() => {
                      if (window.confirm('Are you sure you want to delete?')) {
                        onDelete(item._id)
                      }
                    }}>Delete</Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>


      </div>
    </Page>
  );


};
export default DealerList;