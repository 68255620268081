import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

const LoginPage = () => {
    const history = useHistory();

    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');

    useEffect(()=>{
        console.log('email ',localStorage.getItem('email'))
        if(localStorage.getItem('email')!==null&&localStorage.getItem('email')!==''){
            history.push('/home')
        }
    },[])

    function submit(){

        if(email===''){
            alert("Please enter your email.")
            return
        }
        if(email!=='admin@mail.com'||password!=='admin'){
            alert("Invalid email or password.")
            return
        }
        localStorage.setItem('email',email)
        history.push('/home')
      
    }


    return (
        <div className="login_box mt-5">
            <Form>
                <FormGroup>
                    <Label for="exampleEmail">
                        Email
                    </Label>
                    <Input
                        id="exampleEmail"
                        name="email"
                        placeholder="Enter email"
                        type="email"
                        value={email}
                        onChange={(evt)=>{
                            setEmail(evt.target.value)
                        }}
                    />
                </FormGroup>
                <FormGroup className="mt-2">
                    <Label for="examplePassword">
                        Password
                    </Label>
                    <Input
                        id="examplePassword"
                        name="password"
                        placeholder="Enter password"
                        type="password"
                        value={password}
                        onChange={(evt)=>{
                            setPassword(evt.target.value)
                        }}
                    />
                </FormGroup>
                
                <Button className="mt-2 p-1" onClick={()=>{submit()}}>
                    Submit
                </Button>
            </Form>

        </div>
    )

}
export default LoginPage;