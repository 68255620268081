import { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button } from 'reactstrap'
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import { tempObj } from '../../utils/tempData'
import { Form, Table } from 'react-bootstrap';
import imageUrl from '../../utils/imageBaseUrl';

const CategoryList = ({ }) => {
  const history = useHistory();
  const [categoryList, setCategoryList] = useState([])
  useEffect(() => {
    getCategoryList()
  }, [])

  const getCategoryList = () => {
    axios.get(base_url + '/getAllCategories').then((response) => {
      console.log('getAllCourseResponse: ', response.data)
      setCategoryList(response.data.data)
    });
  }

  const onDelete = (courseId) => {

    axios.get(base_url + '/deleteCategory/' + courseId).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message)
        getCategoryList()
      }
    });
  }
  return (
    <div>
      <Button className='btn-primary-startNow  btn-font  p-2 mt-3 mb-1' onClick={() => { history.push('/add-course/?isEdit=0') }}>Add New Product Category</Button>

      <div class="profile-banner mt-3">
        <h1>Product Categories</h1>
      </div>

      <div >
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Product Category Name</th>
              <th>Icon</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {categoryList?.map((item, index) => (
              <tr>
                <td className='horizontal-align'>
                  {index + 1}</td>
                <td>{item.catName}</td>
                <td><img src={imageUrl + item.catImg} width='60' height='60' />  </td>
                <td>{/*<Button className='ms-3' onClick={() => {
                  history.push('/subCatList/' + item._id)
                }} >View SubCat</Button>*/}
                  <Button className='ms-3' onClick={() => {
                    tempObj = item;
                    history.push('/add-course/?isEdit=1')
                  }}  >Edit</Button>
                  <Button className='ms-3' onClick={() => {
                    if (window.confirm('Are you sure you want to delete?')) {
                      onDelete(item._id)
                    }
                  }}>Delete</Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>


    </div>
  );


};
export default CategoryList;