import { useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import { tempObj } from '../../utils/tempData'
import Page from '../Page';

const AddAnnounce = () => {

    const [reqObj, setReqObj] = useState({});
    const { id } = useParams();
    const history = useHistory()
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const isEdit = params.get('isEdit');


    const [categoryList, setCategoryList] = useState([])
    const [subCategoryList, setSubCategoryList] = useState([])
    const [superSupCategoryList, setSuperSubCategoryList] = useState([])

    useEffect(() => {
        if (isEdit === "1") {
            
            let temp = {
                id: tempObj._id,
                type: tempObj.type,
                title: tempObj.title,
                desc: tempObj.desc
            }
            setReqObj({ ...reqObj, ...temp });
        }
    }, [])


    const onSubmit = () => {

        if (reqObj.type === null || reqObj.type === undefined) {
            alert("Please announcement type.")
            return;
        }

        if (reqObj.title === '' || reqObj.title === undefined) {
            alert("Please enter title.")
            return;
        }

        if (reqObj.desc === '' || reqObj.desc === undefined) {
            alert("Please enter desc.")
            return;
        }

        if (isEdit === "1") {
            let req = {
                "id": id,
                "type": reqObj.type,
                "title": reqObj.title,
                "desc": reqObj.desc
            }
            axios.post(base_url + '/editAnnounce', req).then((response) => {
                if (response.status === 200)
                    toast.success("Announce updated successfully.")
                history.goBack();
            });
        } else {
            axios.post(base_url + '/createAnnouncement', reqObj).then((response) => {
                if (response.status === 200)
                    toast.success("Announce created successfully.")
                history.goBack();
            });
        }



    }
    return (<Page><div className='margin-bothside'>

        <div class="profile-banner mt-3">
            <h1>{isEdit === "1" ? "Update Model" : "Add Model"}</h1>
        </div>
        <Form>

            <FormGroup>
                <Label for="exampleEmail">
                    Select User Type
                </Label>
                <Input
                    className="mb-3"
                    type="select"
                    onChange={(e) => { setReqObj({ ...reqObj, type: e.target.value }); }}
                    value={reqObj.type}
                >
                    <option value={null}>
                        Select User Type
                    </option>
                    <option value={"0"}>
                        Basic
                    </option>
                    <option value={"1"}>
                        Dealer
                    </option>


                </Input>

            </FormGroup>

            <FormGroup>
                <Label for="exampleEmail">
                    Title
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter title"
                    type="text"
                    value={reqObj.title}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, title: e.target.value });
                        }}
                />
            </FormGroup>

            <FormGroup>
                <Label for="exampleEmail">
                    Description
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter description"
                    type="text"
                    value={reqObj.desc}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, desc: e.target.value });
                        }}
                />
            </FormGroup>


            <Button onClick={onSubmit}>
                {isEdit === "1" ? "Update" : "Submit"}
            </Button>
        </Form>
    </div></Page>)

};
export default AddAnnounce;