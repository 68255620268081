import { useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import Page from '../Page';
const EditUpload = () => {

    const [reqObj, setReqObj] = useState({});
    const history = useHistory()

    const onSubmit = () => {
    
        if (reqObj.csv_file === null || reqObj.csv_file === undefined) {
            alert("Please select file to upload.")
            return;
        }

            const formData = new FormData();
            formData.append('csv_file', reqObj.csv_file);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            axios.post(base_url + '/editProductDetailWithCSV', formData, config).then((response) => {
                if (response.status === 200)
                    toast.success("Bulk Uploaded successfully successfully.")
                history.goBack();
            });
        
    }


    return (<Page><div className='margin-bothside'>

        <div class="profile-banner mt-3">
            <h1>{"Bulk Update"}</h1>

        </div>
        <Form>


            <FormGroup>
                <Label for="exampleEmail">
                    Select Excel File
                </Label>
                <Input type="file" name="file" id="exampleFile"
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, csv_file: e.target.files[0] });
                        }
                    } />

            </FormGroup>


            <Button onClick={onSubmit}>
                Update
            </Button>
        </Form>
    </div>
    </Page>)

};
export default EditUpload;