import { useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import Page from '../Page';
const AddSuperSubCat = () => {

    const [reqObj, setReqObj] = useState({});
    const { id } = useParams();
    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const isEdit = params.get('isEdit');

    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);

    useEffect(() => {
        getCategoryList()

        if(isEdit==="1"){
            getSuperSubCategoryDetail()
        }

    }, [])

    const getCategoryList = () => {
        axios.get(base_url + '/getAllCategories').then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setCategoryList(response.data.data)
           
        });
    }

    const getSubCategoryList = (catId) => {
        axios.get(`${base_url}/getSubCategories/` + catId).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setSubCategoryList(response.data.data)
          
        });
    }

    const getSuperSubCategoryDetail = () => {
        axios.get(`${base_url}/getSuperSubCategoryDetail/` + id).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            
            setReqObj({ ...reqObj, supSubCatName: response?.data?.data?.supSubCatName });

        });
    }


    const onSubmit = () => {

        if (isEdit !== "1") {
            if (reqObj.catId === null || reqObj.catId === undefined) {
                alert("Please select devision.")
                return;
            }

            if (reqObj.subCatId === null || reqObj.subCatId === undefined) {
                alert("Please select product.")
                return;
            }
        }
        if (reqObj.supSubCatName === '' || reqObj.supSubCatName === undefined) {
            alert("Please enter first level category.")
            return;
        }

        if(isEdit==="1"){

            let req = {
                supSubCatId:id,
                supSubCatName:reqObj.supSubCatName
            }

            axios.post(base_url + '/editSuperSubCategory', req).then((response) => {
                if (response.status === 200)
                    toast.success("Category created successfully.")
                history.goBack();
            });
    }else{
        axios.post(base_url + '/createSuperSubCategory', reqObj).then((response) => {
            if (response.status === 200)
                toast.success("Category created successfully.")
            history.goBack();
        });
    }

    }
    return (<Page><div className='margin-bothside'>
        <div class="profile-banner mt-3">
            <h1>{isEdit==="1"?"Update Make/Vehicle":"Add Make/Vehicle"}</h1>
        </div>
        <Form>

            {isEdit !== "1" ? <div>
                <FormGroup>
                    <Label for="exampleEmail">
                        Select Product Category
                    </Label>
                    <Input
                        className="mb-3"
                        type="select"
                        value={reqObj.catId}
                        onChange={(e) => { getSubCategoryList(e.target.value); setReqObj({ ...reqObj, catId: e.target.value }); }}
                    >
                        <option value={null}>
                            Select Product Category
                        </option>
                        {categoryList.map((item, index) => {
                            return <option value={item._id}>
                                {item.catName}
                            </option>
                        })}

                    </Input>

                </FormGroup>

                <FormGroup>
                    <Label for="exampleEmail">
                        Select Vehicle Category Management
                    </Label>
                    <Input
                        className="mb-3"
                        type="select"
                        value={reqObj.subCatId}
                        onChange={(e) => { setReqObj({ ...reqObj, subCatId: e.target.value }); }}
                    >
                        <option value={null}>
                            Select Vehicle Category Management
                        </option>
                        {subCategoryList.map((item, index) => {
                            return <option value={item._id}>
                                {item.subCatName}
                            </option>
                        })}

                    </Input>

                </FormGroup>
            </div> : ""}

            <FormGroup>
                <Label for="exampleEmail">
                Make/Vehicle Name
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter your Make/Vehicle name"
                    type="text"
                    value={reqObj.supSubCatName}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, supSubCatName: e.target.value });
                        }}
                />
            </FormGroup>


            <Button onClick={onSubmit}>
                {isEdit==="1"?"Update":"Submit"}
            </Button>
        </Form>
    </div></Page>)

};
export default AddSuperSubCat;