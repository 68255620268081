import { useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import Page from '../Page';
const AddProductDetail = () => {

    const [reqObj, setReqObj] = useState({});
    const { id } = useParams();
    const history = useHistory()
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const isEdit = params.get('isEdit');

    const [categoryList, setCategoryList] = useState([])
    const [subCategoryList, setSubCategoryList] = useState([])
    const [superSupCategoryList, setSuperSubCategoryList] = useState([])
    const [productList, setProductList] = useState([])

    useEffect(() => {
        getCategoryList()
        if (isEdit === "1") {
            getSingleLastProductDetail()
        }
    }, [])

    const getSingleLastProductDetail = () => {
        axios.get(`${base_url}/getSingleLastProductDetail/` + id).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            // setReqObj({ ...reqObj, applicationName: response?.data?.data?.applicationName });
            // setReqObj({ ...reqObj, jkp_part_no: response?.data?.data?.jkp_part_no });
            // setReqObj({ ...reqObj, reference_part_no: response?.data?.data?.reference_part_no });
            // setReqObj({ ...reqObj, dimention: response?.data?.data?.dimention });
            // setReqObj({ ...reqObj, sealType: response?.data?.data?.sealType });
            // setReqObj({ ...reqObj, mrp: response?.data?.data?.mrp });
            setReqObj(response?.data?.data)

        });
    }

    const getCategoryList = () => {
        axios.get(base_url + '/getAllCategories').then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setCategoryList(response.data.data)
        });
    }

    const getSubCategoryList = (catId) => {
        axios.get(`${base_url}/getSubCategories/` + catId).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setSubCategoryList(response.data.data)
        });
    }

    const getSuperSubCategoryList = (subCatId) => {
        axios.get(`${base_url}/getSuperSubCategories/` + subCatId).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setSuperSubCategoryList(response.data.data)
        });
    }

    const getProductListApi = (id) => {
        axios.get(`${base_url}/getProductList/` + id).then((response) => {
            console.log('getAllCourseResponse: ', response.data)
            setProductList(response.data.data)
        });
    }

    const onSubmit = () => {
        // let req = {
        //     catId:catId,
        //     subCatId:subCatId,
        //     supSubCatId:supSubCatId,
        //     parentId:parentId,
        //     applicationName:reqObj.applicationName,
        //     jkp_part_no:reqObj.jkp_part_no,
        //     reference_part_no:reqObj.reference_part_no,
        //     dimention:reqObj.dimention,
        //     sealType:reqObj.sealType,
        //     mrp:reqObj.mrp

        // }

        if (isEdit !== "1") {
            if (reqObj.catId === null || reqObj.catId === undefined) {
                alert("Please select devision.")
                return;
            }

            if (reqObj.subCatId === null || reqObj.subCatId === undefined) {
                alert("Please select product.")
                return;
            }

            if (reqObj.supSubCatId === null || reqObj.supSubCatId === undefined) {
                alert("Please select first level category.")
                return;
            }

            if (reqObj.parentId === null || reqObj.parentId === undefined) {
                alert("Please enter second level category.")
                return;
            }
        }

        if (isEdit === "1") {
            setReqObj({ ...reqObj, prodDetailId: id });
            axios.post(base_url + '/editProductDetail', reqObj).then((response) => {
                if (response.status === 200)
                    toast.success("Category created successfully.")
                history.goBack();
            });
        } else {
            axios.post(base_url + '/addProductDetail', reqObj).then((response) => {
                if (response.status === 200)
                    toast.success("Category created successfully.")
                history.goBack();
            });
        }

    }
    return (<Page><div className='margin-bothside'>
        <div class="profile-banner mt-3">
            <h1>{isEdit==="1"?"Update Product Detail":"Add Product Detail"}</h1>
        </div>
        <Form>

            {isEdit !== "1" ? <div>
                <FormGroup>
                    <Label for="exampleEmail">
                        Select Product Category
                    </Label>
                    <Input
                        className="mb-3"
                        type="select"
                        onChange={(e) => { getSubCategoryList(e.target.value); setReqObj({ ...reqObj, catId: e.target.value }); }}
                    >
                        <option value={null}>
                            Select Product Category
                        </option>
                        {categoryList.map((item, index) => {
                            return <option value={item._id}>
                                {item.catName}
                            </option>
                        })}

                    </Input>

                </FormGroup>

                <FormGroup>
                    <Label for="exampleEmail">
                        Select Vehicle Category
                    </Label>
                    <Input
                        className="mb-3"
                        type="select"
                        onChange={(e) => { getSuperSubCategoryList(e.target.value); setReqObj({ ...reqObj, subCatId: e.target.value }); }}
                    >
                        <option value={null}>
                            Select Vehicle Category
                        </option>
                        {subCategoryList.map((item, index) => {
                            return <option value={item._id}>
                                {item.subCatName}
                            </option>
                        })}

                    </Input>

                </FormGroup>


                <FormGroup>
                    <Label for="exampleEmail">
                        Select Make/Vehicle
                    </Label>
                    <Input
                        className="mb-3"
                        type="select"
                        onChange={(e) => { getProductListApi(e.target.value); setReqObj({ ...reqObj, supSubCatId: e.target.value }); }}
                    >
                        <option value={null}>
                            Select Make/Vehicle
                        </option>
                        {superSupCategoryList.map((item, index) => {
                            return <option value={item._id}>
                                {item.supSubCatName}
                            </option>
                        })}

                    </Input>

                </FormGroup>


                <FormGroup>
                    <Label for="exampleEmail">
                        Select Model
                    </Label>
                    <Input
                        className="mb-3"
                        type="select"
                        onChange={(e) => { setReqObj({ ...reqObj, parentId: e.target.value }); }}
                    >
                        <option value={null}>
                            Select Model
                        </option>
                        {productList.map((item, index) => {
                            return <option value={item._id}>
                                {item.prodName}
                            </option>
                        })}

                    </Input>

                </FormGroup>
            </div> : ""}


            <FormGroup>
                <Label for="exampleEmail">
                    Application Name:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter application name"
                    type="text"
                    value={reqObj.applicationName}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, applicationName: e.target.value });
                        }}
                />
            </FormGroup>

            <FormGroup>
                <Label for="exampleEmail">
                    Binbow Part Notes:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter Binbow part number"
                    type="text"
                    value={reqObj.jkp_part_no}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, jkp_part_no: e.target.value });
                        }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="exampleEmail">
                   OE Number:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter enter reference part number"
                    type="text"
                    value={reqObj.reference_part_no}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, reference_part_no: e.target.value });
                        }}
                />
            </FormGroup>

            <FormGroup>
                <Label for="exampleEmail">
                    Dimention:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter dimention"
                    type="text"
                    value={reqObj.dimention}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, dimention: e.target.value });
                        }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="exampleEmail">
                    Product type:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter seal type"
                    type="text"
                    value={reqObj.sealType}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, sealType: e.target.value });
                        }}
                />
            </FormGroup>
            <FormGroup>
                <Label for="exampleEmail">
                    MRP:
                </Label>
                <Input
                    id="exampleCName"
                    name="course_name"
                    placeholder="Enter MRP"
                    type="number"
                    value={reqObj.mrp}
                    onChange={
                        (e) => {
                            setReqObj({ ...reqObj, mrp: e.target.value });
                        }}
                />
            </FormGroup>

            <Button onClick={onSubmit}>
                {isEdit==="1"?"Update":"Submit"}
            </Button>
        </Form>
    </div></Page>)

};
export default AddProductDetail;