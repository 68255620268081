import logo from './logo.svg';
import './App.css';
import './assets/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './pages/Dashboard';
import 'react-toastify/dist/ReactToastify.css';
import  {ToastContainer}  from 'react-toastify';

function App() {
  return (
    <div className="App">
    <ToastContainer/>
      <Dashboard/>
    </div>
  );
}

export default App;
