
import NavbarComponent from '../components/NavbarComponent';
import CategoryList from '../components/cat/CategoryList';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AddCourse from '../components/cat/AddCourse';
import SubCatList from '../components/subCat/SubCatList';
import AddSubCat from '../components/subCat/AddSubCat';
import SuperSubCatList from '../components/super_sub_cat/SuperSubCatList';
import AddSuperSubCat from '../components/super_sub_cat/AddSuperSubCat';
import ProductList from '../components/product/ProductList';
import AddProduct from '../components/product/AddProduct';
import ProductDetailList from '../components/product_detail/ProductDetailList';
import AddProductDetail from '../components/product_detail/AddProductDetail';
import InquiryList from '../components/inquiry/InquiryList';
import HomePage from '../components/HomePage';
import LoginPage from './LoginPage';
import Announcement from '../components/inquiry/Announcement';
import AddAnnounce from '../components/inquiry/AddAnnounce';
import DealerList from '../components/users/DealerList';
import AddDealer from '../components/users/AddDealer';
import BulkUpload from '../components/product/BulkUpload';
import EditUpload from '../components/product_detail/EditUpload';

const Dashboard = ({ }) => {
  return (
    <div >
      <Router >
        <div >
          <Route path='/home' component={HomePage} exact />
          <Route path='/add-course' component={AddCourse} exact />
          <Route path='/subCatList/:catId' component={SubCatList} exact />
          <Route path='/addSubCat/:id' component={AddSubCat} exact />
          <Route path='/superSubCatList/:id' component={SuperSubCatList} exact />
          <Route path='/addSuperSubCat/:id' component={AddSuperSubCat} exact />
          <Route path='/productList' component={ProductList} exact />
          <Route path='/addProduct/:id' component={AddProduct} exact />
          <Route path='/productDetailList/:catId/:subCatId/:supSubCatId/:parentId' component={ProductDetailList} exact />
          <Route path='/addProductDetail/:id' component={AddProductDetail} exact />
          <Route path='/inquiryList' component={InquiryList} exact />
          <Route path='/' component={LoginPage} exact />
          <Route path='/announce' component={Announcement} exact />
          <Route path='/add-announce/:id' component = {AddAnnounce} />
          <Route path='/dealers' component = {DealerList} />
          <Route path='/addUser/:id' component = {AddDealer} />
          <Route path='/bulkUpload/:id' component={BulkUpload} exact />
          <Route path='/editUpload' component={EditUpload} exact />
          
        </div>
      </Router>


    </div>
  );
};
export default Dashboard;