import { useEffect, useState, } from "react";
import { useHistory, useParams } from 'react-router-dom';
import CategoryList from "./cat/CategoryList";
import Page from "./Page";
import ProductList from "./product/ProductList";
import ProductDetailList from "./product_detail/ProductDetailList";
import SubCatList from "./subCat/SubCatList";
import SuperSubCatList from "./super_sub_cat/SuperSubCatList";



const HomePage = () => {
    const history = useHistory()

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const selectedMenu = params.get('select') === null ? '0' : params.get('select');
    console.log('param value: ', params.get('select'));

    useEffect(() => {
        if (localStorage.getItem('email') === null || localStorage.getItem('email') === '') {
            history.push('/')
        }
    }, [])

    // const [selectedMenu, setSelectedMenu] = useState(0)
    return (
        <>
            <Page>
                <section class="container">
                    <div class="row">
                        <div class="col-md-3 course-left-sec">
                            <label class={`left-radio-heading cursor-pointer ${selectedMenu === '0' ? "selected-menu" : ""}`} onClick={() => { history.push('/home?select=0') }}>Product Category Management</label>
                            <label class={`left-radio-heading cursor-pointer ${selectedMenu === '1' ? "selected-menu" : ""}`} onClick={() => { history.push('/home?select=1') }}>Vehicle Category Management</label>
                            <label class={`left-radio-heading cursor-pointer ${selectedMenu === '2' ? "selected-menu" : ""}`} onClick={() => { history.push('/home?select=2') }}>Make/Vehicle</label>
                            <label class={`left-radio-heading cursor-pointer ${selectedMenu === '3' ? "selected-menu" : ""}`} onClick={() => { history.push('/home?select=3') }}>Model</label>
                            <label class={`left-radio-heading cursor-pointer ${selectedMenu === '4' ? "selected-menu" : ""}`} onClick={() => { history.push('/home?select=4') }}>Product Detail</label>
                            <label class={`left-radio-heading cursor-pointer ${selectedMenu === '5' ? "selected-menu" : ""}`} onClick={() => { history.push('/bulkUpload/null') }}>Bulk Upload</label>

                            <label class="left-radio-heading cursor-pointer" onClick={() => { localStorage.clear(); history.push('/') }}>Logout</label>
                        </div>
                        <div class="col-md-9 course-right-sec">
                            <div class="d-flex justify-content-between filtersection mb-3">
                                <div class="">
                                    <form class="d-flex containsearchbox">
                                        <input class="form-control inputsearch " type="text" placeholder="Search" />
                                        <button class="btn searchbtn-body" type="button"><i class="fa fa-search"
                                            aria-hidden="true"></i></button>
                                    </form>
                                </div>

                            </div>

                            {
                                selectedMenu === '0' ? <CategoryList /> : selectedMenu === '1' ? <SubCatList /> : selectedMenu === '2' ? <SuperSubCatList /> : selectedMenu === '3' ? <ProductList /> : selectedMenu === '4' ? <ProductDetailList /> : ""
                            }
                        </div>
                    </div>
                </section>
            </Page>
        </>
    )



}

export default HomePage;