import { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button } from 'reactstrap'
import axios from 'axios';
import base_url from '../../utils/config'
import { toast } from 'react-toastify';
import moment from 'moment'
import Page from '../Page';
const InquiryList = ({ }) => {
  const history = useHistory();
  const [inquiryList, setInquiryList] = useState([])
  useEffect(() => {
    getInquiryList()
  }, [])

  const getInquiryList = () => {
    axios.get(base_url + '/getInquiryList').then((response) => {
      console.log('getAllCourseResponse: ', response.data)
      setInquiryList(response.data.data)
    });
  }

  const onUpdateStatus = (id, status) => {
    let reqObj = {
      id: id,
      status: status
    }
    axios.post(base_url + '/updateInquiryStatus', reqObj).then((response) => {
      if (response.status === 200)
        toast.success("Status created successfully.")
      getInquiryList();
    });
  }
  return (
    <Page><div className='container'>

      <div class="profile-banner mt-3">
        <h1>Inquiry List</h1>
      </div>

      <div >
        {inquiryList?.map((item, index) => (<Card className='mt-3' style={{
          borderColor: '#333'
        }}>
          <CardBody>
            <CardTitle >
              <div className='horizontal-align'>
                <div><b>Dealer Name: </b>{item?.userDetail?.name}</div>
                <div className='ms-3'><b>Contact: </b>{item?.userDetail?.mobile_number}</div>
              </div>
              <br />
              <div className='horizontal-align'>
                <div><b>Application Name: </b>{item?.prodDetail?.applicationName}</div>
                <div className='ms-3'><b>Binbow part no: </b>{item?.prodDetail?.jkp_part_no}</div>
              </div>
              <br />
              <div className='horizontal-align'>
                <div><b>Inquiry Time: </b>{moment(item?.createdAt).format('DD/MM/YYYY HH:mm')}</div>

              </div>

            </CardTitle>

            <div className='horizontal-align'>

              <Button className='ms-3' onClick={() => {
                if (item?.status === 0)
                  onUpdateStatus(item?._id, 1)
              }} >{item?.status === 1 ? "Approved" : "Approve"}</Button>

              <Button className='ms-3' onClick={() => {
                if (item.status === 0)
                  onUpdateStatus(item?._id, 2)
              }} >{item?.status === 2 ? "Rejected" : "Reject"}</Button>

            </div>

          </CardBody>
        </Card>))}
      </div>


    </div>
    </Page>
  );


};
export default InquiryList;